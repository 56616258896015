export const ROUTES = {
  HOME: '/',
  RUBRIC: (slug: string) => `/rubric/${slug}`,
  CATALOG: (slug: string) => `/catalog/${slug}`,
  PRODUCT: (slug: string) => `/product/${slug}`,
  SEARCH: (query: string) => `/search?term=${query}`,
  ABOUT_US: '/about-us',
  DELIVERY: '/delivery',
  PAYMENT: '/payment',
  BONUS_PROGRAM: '/bonus-program',
  REVIEWS: '/reviews',
  CONTACTS: '/contacts',
  CHECKOUT: '/checkout',
  DOLYAME: '/dolyame',
  PODELI: '/podeli',
  YANDEX_SPLIT: '/yandex-split',
  FAVORITES: '/favorites',
  PARTNERS: '/partners',
  KORPORATIVNYM_KLIENTAM: '/korporativnym-klientam',
  BLOG: 'https://azalianow.ru/blog/',
  PUBLIC_OFFER: '/public-offer',
  RETURNS_POLICY: '/returns-policy',
  USER_AGREEMENT: '/user-agreement',
  PRIVACY_POLICY: '/privacy-policy',
  SITEMAP: '/sitemap',
  CABINET: {
    PROFILE: '/cabinet/profile',
    ORDERS: '/cabinet/orders',
    BONUSES: '/cabinet/bonuses',
    PROMOTIONS: '/cabinet/promotions',
  },
  PROMOTIONS: {
    CABINET: '/cabinet/promotions',
    POLICY_INFO: (id: string) => `/promotions/info/${id}`,
    DETAILED: (id: string) => `/promotions/${id}`,
  },
};

export const RUBRIC_URL = '/rubric';
export const CATEGORY_URL = '/catalog';
export const CVETI_URL = '/catalog/bukety';

export const getRubricUrl = (slug: string) => `${RUBRIC_URL}/${slug}`;

export const getCategoryUrl = (slug: string): string => `${CATEGORY_URL}/${slug}`;
export const getCvetiUrl = (slug: string): string => `${CVETI_URL}/${slug}`;

export const getColorUrl = (slug: string): string => `${CVETI_URL}/${slug}`;

export const getByPriceUrl = (from: number, to: number): string =>
  `/catalog/bukety?prices=${from}&prices=${to}&sort=rank`;
